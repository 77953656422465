import { Card, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import Image from '#/components/shared/ui/Image';
import useLocales from '#/hooks/useLocales';
import Iconify from './ui/Iconify';

type Props = {
  title: string;
  icon?: string;
  description?: string;
  actions?: React.ReactNode;
  tooltipTitle?: string;
  showTooltip?: boolean;
  estimate?: string;
};

export default function DashboardCard({
  title,
  icon,
  description,
  actions,
  tooltipTitle,
  showTooltip = false,
  estimate,
}: Props) {
  const { translate } = useLocales();

  return (
    <Tooltip title={tooltipTitle} disableHoverListener={!showTooltip}>
      <Card
        sx={{
          p: 2,
          flex: 1,
        }}
      >
        {icon && (
          <Image
            src={icon}
            sx={{
              width: 60,
              height: 60,
              marginBottom: 2,
            }}
            alt={`${title} - ${title}`}
          />
        )}
        <Stack spacing={2}>
          <Typography variant="subtitle1" component="h6" sx={{ marginTop: 2 }}>
            {String(translate(title))}
          </Typography>
          <Typography fontWeight="normal" sx={{ height: '60px' }}>
            {translate(description as string)}
          </Typography>
          {estimate && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Iconify icon="ix:average" />
              <Typography fontWeight="normal">
                {translate('global.dashCardInfos.timeToComplete', {
                  estimate: translate(estimate),
                })}
              </Typography>
            </Stack>
          )}
        </Stack>
        {actions}
      </Card>
    </Tooltip>
  );
}
